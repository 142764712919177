<template>
  <div></div>
</template>

<script setup>
import { useGlobalStore } from "~/stores/global";
const globalStore = useGlobalStore();
const myLandbot = ref();

const initLandbot = () => {
    var s = document.createElement('script');
    s.type = 'text/javascript';
    s.async = true;
    s.addEventListener('load', function() {
        myLandbot.value = new Landbot.Livechat({
            configUrl: 'https://storage.googleapis.com/landbot.online/v3/'+globalStore.siteSettings.landbot_id+'/index.json',
        });
        globalStore.setLandbotChatBox(myLandbot.value)
    });
    s.src = 'https://cdn.landbot.io/landbot-3/landbot-3.0.0.js';
    var x = document.getElementsByTagName('script')[0];
    x.parentNode.insertBefore(s, x);
}

onMounted(() => {
    if (globalStore.siteSettings.landbot_chat == true) {
        initLandbot();
    }
});
</script>